import React from 'react'
import Layout from '../components/layout'
import ganeshaGIF from '../assets/images/ganesha404.gif'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="notfound">
        <section id="one">
            <div className="inner">
                <center><img src={ganeshaGIF} alt="NamasteGod Page Not Found Ganesha" /></center>
                <h1>Oops! Not Found</h1>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
